<template>
  <div class="join">
    <topMenu :is-position="true" v-if="false"></topMenu>
    <div class="logo_bg">
      <el-image :src="url" fit="cover" style="height: 330px; width: 100%;"></el-image>
    </div>
    <div class="middle_content">
      <div class="top_title">
        <h3 style="padding-left: 13px;font-size: 32px;color: #B01F24;">{{currentPosition.name}}</h3>
        <div style="font-size: 20px;">
          <el-image :src="departmentUrl"></el-image>
          <span class="f_w">需求部门：</span>
          <span>{{currentPosition.department ? currentPosition.department: '——————'}}</span>
        </div>
        <a class="back" href="/" >
          <img src="@/assets/arrow_icon.png" alt="" width="23px">
          返回列表
        </a>
      </div>
      <div class="hr"></div>
      <div class="detail">
        <h6 v-show="currentPosition.detail.goal != ''">岗位目的</h6>
        <div v-show="currentPosition.detail.goal != ''" style="margin-bottom: 10px;">{{currentPosition.detail.goal}}</div>
        <div v-show="currentPosition.detail.egGoal != ''" style="margin-bottom: 40px;">{{currentPosition.detail.egGoal}}</div>
        <h6 style="padding-top: 20px;">主要职责</h6>
        <div style="margin-bottom: 20px;">
          <div v-for="d in currentPosition.detail.duty" :key="d" class="text">
            <span v-show="d.title !== ''" style="font-weight: bold;">{{d.title}}</span>
            <div v-for="r in d.content" :key="r" class="text">{{r}}</div>
          </div>
        </div>
        <h6>任职要求</h6>
        <div>
          <div v-for="d in currentPosition.detail.require" :key="d" class="text">
            <span v-show="d.title !== ''" style="font-weight: bold; color: #666; padding-top: 10px; display: inline-block;">{{d.title}}</span>
            <div v-for="r in d.content" :key="r" class="text">{{r}}</div>
          </div>
        </div>
        <div v-if="currentPosition.detail.remarks" v-html="currentPosition.detail.remarks"></div>
      </div>
      <div class="contact" style="padding-top: 20px;">
        <h3 style="font-size: 32px;color: #B01F24;">联系我们</h3>
        <div class="basic flex justify_content">
          <div class="basic_left">
            <div style="display: flex;align-items: center;">
              <img src="../assets/phone.png" width="24px" height="24px" style="margin-right: 8px;"/>
              <span>021-68587662</span>
            </div>
            <div style="display: flex;align-items: center;">
              <img src="../assets/email.png" width="19px" height="19px;"/>
              <span>{{ [1, 2].includes(currentPosition.id) ? 'hr.admin@genesisfin.net' : 'shan.wang@genesisfin.net' }}</span>
            </div>
          </div>
          <div class="code_box">
            <div style="margin-right: 53px;">
              <el-image :src="wx"></el-image>
              <div class="text">微信公众号</div>
            </div>
            <div>
              <el-image :src="wx2"></el-image>
              <div class="text">元小年微信号</div>
            </div>
          </div>
        </div>
        <div class="subway_box" style="margin-top: -70px;">
          <h3 style="font-size: 32px;color: #B01F24;">公司地址</h3>
          <div style="display: flex;align-items: center;">
            <img src="../assets/location.png" width="19px" height="19px"/>
            <span>上海市浦东新区浦东南路1118号鄂尔多斯国际大厦9楼</span>
          </div>
          <div style="padding: 15px 0 0 30px; color: #999; line-height: 40px;">
            <div style="display: flex;align-items: center;">
              <img src="../assets/subway.png" width="16px" height="16px" style="margin-right: 6px;"/>
              <span>【9号线-商城路站】 3号口出站，步行6分钟，约400米</span>
            </div>
            <div class="no_img">【2号线-东昌路站】 4号口出站，步行13分钟，约840米</div>
            <div class="bus_box">
              <img src="../assets/bus.png" width="19px" height="19px;" style="margin-right: 4px;"/>
              <span>【浦东南路张杨路】130路、985路、338路、339路、583路、783路、791路、961路、隧道三线、隧道夜宵线</span>
            </div>
            <div class="ship_box">
              <img src="../assets/ship.png" width="23px" height="17px;"/>
              <span>【杨家渡渡口】--杨复线 步行距离960米，约13分钟</span>
            </div>
          </div>
          <!-- <div style="display: flex;align-items: center; padding-top: 20px;">
            <img src="../assets/location.png" width="19px" height="19px"/>
            <span>香港尖沙咀广东道9号海港城港威大厦6座29楼2907-8室</span>
          </div> -->
        </div>
        <a class="back" href="/" style="bottom: 0">
          <img src="@/assets/arrow_icon.png" alt="" width="23px">
          返回列表
        </a>
      </div>
    </div>
    <div class="footer flex" id="footer">
      <div style="width: 80%; margin-left: 10%;">
        <baidu-map class="map" center="上海市浦东新区浦东南路1118号鄂尔多斯国际大厦" :zoom="16">
          <bm-marker :position="markerPointSh" :dragging="true" @click="infoWindowOpen('shanghai')">
            <bm-info-window :show="showSh" @close="infoWindowClose('shanghai')" @open="infoWindowOpen('shanghai')" style="font-size:13px">
              上海市浦东新区浦东南路1118号<br>
              鄂尔多斯国际大厦9F
            </bm-info-window>
          </bm-marker>
        </baidu-map>
        <div style="text-align: center; padding-top: 10px;">上海办公地</div>
      </div>
    </div>
    <div class="copy_right">
      Copyright © 2015-2024   |   版权所有  上海元年金融信息服务有限公司     |   上海市通信管理局  沪ICP备15028722号-2   |   沪公网安备31010902003073号
    </div>
  </div>
</template>

<script>
import wxCode from '../assets/wx.jpg'
import wx2Code from '../assets/wx2.jpg'
import url from '../assets/position_bg.jpg'
import departmentUrl from '../assets/department_icon.png'
import {positionList} from './constData'


import topMenu from './menu'

export default {
  name: 'Join',
  props: {

  },
  components: {topMenu},
  data() {
    return {
      wx:wxCode,
      wx2:wx2Code,
      url,
      departmentUrl,
      markerPointSh:{lng: 121.52198, lat: 31.233324},
      markerPointBj:{lng: 116.46347, lat: 39.91322},
      showSh:false,
      showBj:false,
      positionList,
      id:'',
      currentPosition:{
        department:'',
        detail:{
          goal:{}
        }
      }
    }
  },
  mounted(){
    this.id = this.$route.query.id;
    this.currentPosition = this.positionList.find((p)=>p.id===Number(this.id))
  },
  methods: {
    infoWindowClose (address) {
      if(address === 'shanghai') {
        this.showSh = false
      } else {
        this.showBj = false
      }
    },
    infoWindowOpen (address) {
      if(address === 'shanghai') {
        this.showSh = true
      } else {
        this.showBj = true
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .middle_content{
    padding: 0 110px;
    .hr{
      height: 17px;
      background: #E5E5EA;
    }
    .top_title{
      font-size: 16px;
      padding: 66px 0 26px 0;
      .el-image{
          width: 27px;
          height: 27px;
          margin-right: 17px;
      }
      >div{
          line-height: 27px;
      }
    }
  }
  .code_box .el-image{
      width: 126px;
      height: 126px;
  }
  .code_box{
      display: flex;
      align-items: center;
      justify-content:center;
      margin-bottom: 40px;
      font-size: 20px;
  }
  .code_box .text{
      text-align: center;
      margin-top: 10px;
  }

  .back{
    position: absolute;
    right: 0;
    bottom: 15px;
    width: 115px;
    height: 46px;
    background: #F5F5F5;
    color: #8A8A8A;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.top_title{
  position: relative;
}
.detail{
  font-size: 20px;
  padding: 50px 0;

  h6{
    // color: #B01F24;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .text{
    line-height: 2;
  }
}
.contact{
  position: relative;
  .basic{
    display: flex;
    font-size: 20px;
    .basic_left{
      margin-right: 170px;
      >div{
        margin-bottom: 20px;
        line-height: 1;
        img{
          margin-right: 13px;
          vertical-align: text-top;
        }
      }
    }
  }
  .subway_box{
    margin-bottom: 40px;
    font-size: 20px;
    .no_img{
      margin:0 0 0 22px;
    }
  }
}
  .footer{
    // width: 1227px;
    width: calc(100% - 220px);
    margin: 0 auto;
    .map {
      width: 100%;
      height: 424px;
    }
  }
  .copy_right{
    margin-top: 60px;
    background: #1f364d;
    width: 100%;
    color: #E5E5E5;
    text-align: center;
    height: 37px;
    line-height: 37px;
    font-size: 12px;
  }
  .code_box{
    .text{
      font-size: 20px;
      padding-top: 10px;
    }
  }
</style>
<style>
.join .el-image{
  overflow: inherit!important;
}

</style>

